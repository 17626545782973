@import "../../sass/_colors", "../../sass/_mixins", "../../sass/_misc";

.cv-section {
  margin-top: $section-margin;
  > p {
    margin-bottom: $item-margin;
  }
  h2 {
    @include commented-text;
    word-break: break-all;
  }
}
