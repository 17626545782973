@import "../../../sass/_colors", "../../../sass/_mixins", "../../../sass/_fonts",
  "../../../sass/_misc";

.object-container {
  padding-left: 1.5rem;

  &::before {
    @include add-brackets("{");
  }

  &::after {
    @include add-brackets("}");
  }

  h3 {
    font-size: 16px;
    @include style-text($object-torquoise, $text-serif);
  }

  p {
    @include style-text($object-orange, $text-serif);
  }

  p:after {
    content: ",";
    color: $text-white;
  }

  span {
    color: $text-white;
    margin: 0 0.2rem;
  }

  :not(:last-child) {
    margin-bottom: $item-margin;
  }
}
