@import "../../../../sass/_colors", "../../../../sass/_mixins",
  "../../../../sass/fonts", "../../../../sass/misc";

.terminal-container {
  > :not(:last-child) {
    margin-bottom: calc(#{$item-margin} + 0.5rem);
  }
  .terminal-block {
    > :not(:last-child) {
      margin-bottom: 0.3rem;
    }

    .terminal-like {
      @include terminal-text;
    }

    .html-like {
      @include html-text;
    }

    .bracketed {
      @include angle-brackets;
    }

    p {
      font-family: $title-sans;
      color: $object-torquoise;
    }

    p:last-child {
      color: $text-white;
    }

    .inline-block {
      display: inline-block;
    }

    .piped {
      font-weight: inherit;
      @include pipe-before;
    }

    a,
    button {
      cursor: pointer;
      font-family: $text-serif;
      color: $object-orange;
      text-decoration: underline;
    }
  }
}
