.badges-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  > :not(:last-child) {
    margin-right: 20px;
  }
  .logo {
    width: 80px;
    height: 80px;
    display: flex;
    &#linkedin-logo {
      width: 100px;
      height: 100px;
    }
  }
  .logo:hover {
    animation: blinker 0.7s ease-in;
    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    }
  }
}
