@import "_colors", "_fonts", "_buttons", "_mixins", "_misc", "_animations",
  "_resets", "animate.css";

// Components
@import "../components/Main/PersonalInfoSection/PersonalInfoSection";
@import "../components/Header/_HeaderSection.scss";
@import "../components/Header/SocialBadges";
@import "../components/Footer/Footer";

// Centering elements in big container
.page-container {
  max-width: 1200px;

  padding: 50px 20px;

  margin: 0 auto;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 700px) {
    padding: 70px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5vw;
    grid-template-areas:
      " . la"
      "hd hd"
      "sk ab"
      "wo ed"
      "we we"
      "al al";
    .header-container {
      grid-area: hd;
      flex-flow: row nowrap;
      justify-content: center;
      > :not(:last-child) {
        margin-right: 40px;
      }
    }
    .skills-section {
      grid-area: sk;
    }
    .web_dev_portfolio-section {
      grid-area: we;
    }
    .algorithms_portfolio-section {
      grid-area: al;
    }
    .about-section {
      grid-area: ab;
    }
    .contact-section {
      grid-area: co;
    }
    .work-section {
      grid-area: wo;
    }
    .education-section {
      grid-area: ed;
    }
    .language-container {
      grid-area: la;
      justify-self: flex-end;
    }
  }

  @media (min-width: 900px) {
    align-items: flex-start;
    grid-template-areas:
      " . la"
      "hd sk"
      "hd ab"
      "wo ed"
      "we al";
    .header-container {
      flex-flow: column nowrap;
      align-self: center;
    }
  }
}
