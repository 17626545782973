$background-color: #1e1e1e;
$background-color_light: #fff;
$lighter-background: #252526;
$light-blue: #007acc;
$light-gray: #858585;
$comment-green: #6a9956;
$object-torquoise: #9cdcfe;
$object-orange: #ce9178;
$terminal-yellow: #dccd79;
$text-white: #d4d4d4;
$text-black: #333;
