@import "../../sass/animations";

#show-more-button {
  display: block;
  margin: 0 auto;

  width: 30px;
  height: 30px;

  cursor: pointer;

  @include defaultTransition();
  &:hover {
      opacity: 0.5;
  }
  &.open {
    transform: rotate(180deg);
  }
}
