@import "./colors";

$section-margin: 3rem;

$item-margin: 1.2rem;

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;

  color: $text-white;

  font-family: $title-sans;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.4;

  list-style: none;

  word-wrap: break-word;
}

body {
  background-color: $background-color;
}

a {
  word-break: break-all;
}
