%btn {
  display: inline-block;
  border-radius: 5px;
  padding: 8px 20px;
  margin: 3px;

  &:hover {
    transform: scale(0.98);
  }
}

.btn-primary {
  @extend %btn;
}
