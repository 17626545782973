footer {
  margin-top: $section-margin;
  font-size: 0.7rem;

  > * {
    display: block;
    float: right;
  }

  > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
