@mixin defaultTransition {
  transition: all ease-in-out 0.3s;
}

@mixin dropdownAnimation($times: 1) {
  animation: $times ease-in-out 0.6s blink;

  @keyframes blink {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(15px);
      opacity: 0;
    }
  }
}
