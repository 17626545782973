@import "/src/sass/_colors.scss";

#modal-wrapper {
  position: fixed;
  z-index: 100;

  overflow: hidden;

  padding: 10px;
  padding-top: 50px;

  background-color: white;

  border-radius: 12px;

  width: auto;
  height: auto;

  left: 50%;
  top: 20%;
  transform: translateX(-50%) translateY(-20%);

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  display: flex;
  justify-content: center;
  align-items: center;

  .close-icon {
    cursor: pointer;
    
    display: flex;

    width: 30px;
    height: 30px;

    position: absolute;
    top: 10px;
    right: 10px;
  }
  video {
    max-width: min(1150px, 90vw);
    max-height: 90vh;
  }
}

#modal-backdrop {
  background-color: rgba($background-color, 0.3);
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}
