@import "../../sass/_colors";
@import "../../sass/_misc";
@import "../../sass/_fonts";
@import "../../sass/_mixins";

.header-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  > :not(:last-child) {
    margin-bottom: $item-margin;
  }

  > :first-child {
    max-width: 350px;
  }

  .title-subtitle-container {
    max-width: 400px;
    h1 {
      color: $light-blue;
      letter-spacing: 2px;
      font-size: 2rem;
      font-weight: bold;
    }

    p {
      color: $text-white;
      font-size: 0.9rem;
      letter-spacing: 3px;
      font-weight: 600;
      line-height: 1.7;
      font-weight: bold;
    }

    h1,
    p {
      font-family: $title-sans;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .picture-container {
    .portrait {
      display: block;
      margin: 0 auto;
      min-width: 200px;
      width: 100%;
      max-width: 300px;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
  }
}
