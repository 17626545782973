@import "../sass/_colors";
@import "../sass/_fonts";

@mixin style-text($font-color, $font) {
  color: $font-color;
  font-weight: 400;
  line-height: 1.3;
  display: inline;
  font-family: $font;

  @if $font == $text-serif {
    letter-spacing: 0.6px;
  }
}

@mixin big-brackets {
  position: relative;
  color: $light-gray;

  &::before,
  &::after {
    font-size: 5rem;
    opacity: 0.2;
    font-weight: bold;
    letter-spacing: 10px;
  }

  &::before {
    content: "/*";
  }
  &::after {
    float: right;
    content: "*/";
  }
}

@mixin add-brackets($content) {
  margin-left: -1.5rem;
  content: $content;
  color: $text-white;
  font-size: 1.2rem;
}

@mixin commented-text {
  color: $comment-green;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 24px;

  margin-bottom: 0.7rem;

  &::before {
    color: inherit;
    content: "// ";
  }
}

@mixin html-commented-text {
  color: $comment-green;
  letter-spacing: 1px;
  font-size: 20px;

  margin-bottom: 0.7rem;

  &:before {
    color: inherit;
    content: "/* ";
  }
  &:after {
    color: inherit;
    content: " */";
  }
}

@mixin terminal-text {
  font-family: $text-serif;
  font-weight: bold;
  color: $terminal-yellow;

  &::before {
    font-weight: inherit;
    color: inherit;
    content: "~$ ";
  }
}

@mixin html-text {
  font-family: $text-serif;
  font-weight: bold;
  color: $terminal-yellow;

  &::before,
  &::after {
    font-weight: inherit;
    color: inherit;
  }

  &::before {
    content: "< ";
  }
  &::after {
    content: " />";
  }
}

@mixin angle-brackets {
  &::before {
    color: $text-white;
    display: inline-block;
    content: ">>";
    margin-right: 0.5rem;
  }
}

@mixin pipe-before {
  color: inherit;
  display: inline-block;

  &::before {
    color: inherit;
    content: "|";
    margin: 0 0.3rem;
  }
}
