.dropdown-container,
.dropdown-container-enter-active,
.dropdown-container-appear-active {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.dropdown-container-enter-done,
.dropdown-container-appear-done {
  max-height: 2000px;
  opacity: 1;
  transition: all 450ms ease-in-out;
}
